import { ContextApi } from '@pulsex/localization'
import { PageMeta } from './types'

const domain = 'https://tokens.app.pulsex.com'

export const DEFAULT_META: PageMeta = {
  title: 'PulseX',
  description: 'PulseX is Premier DEX on PulseChain.',
  image: `${domain}/images/logo.png`,
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
    // } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    //   basePath = '/voting/proposal'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('PulseX')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('PulseX')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('PulseX')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('PulseX')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('PulseX')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('PulseX')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('PulseX')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('PulseX')}`,
      }
    case '/stake':
      return {
        title: `${t('Stake')} | ${t('PulseX')}`,
      }
    // case '/voting':
    //   return {
    //     title: `${t('Voting')} | ${t('PulseX')}`,
    //   }
    // case '/voting/proposal':
    //   return {
    //     title: `${t('Proposals')} | ${t('PulseX')}`,
    //   }
    // case '/voting/proposal/create':
    //   return {
    //     title: `${t('Make a Proposal')} | ${t('PulseX')}`,
    //   }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('PulseX Info & Analytics')}`,
        description: 'View statistics for PulseX exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('PulseX Info & Analytics')}`,
        description: 'View statistics for PulseX exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('PulseX Info & Analytics')}`,
        description: 'View statistics for PulseX exchanges.',
      }
    default:
      return null
  }
}
