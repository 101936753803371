import { useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Currency, TradeV1, TradeV2, TradeType } from '@pulsex/sdk'
import { Text, Box, Flex, ChevronDownIcon } from '@pulsex/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from '@pulsex/localization'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { MouseoverTooltip } from 'components/Tooltip'
import { RowFixed } from 'components/Layout/Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import AnimatedDropdown from './AnimatedDropdown'
import TradePrice from './TradePrice'

const StyledHeaderRow = styled(Box)`
  cursor: pointer;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  padding: 0.5rem 0.5rem;
`
const StyledDetailsRow = styled(Box)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.1rem 0.5rem;
`
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: '10px';
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const RotatingArrow = styled(ChevronDownIcon)<{ open?: boolean; hidden?: boolean }>`
  hidden: ${({ hidden }) => (hidden ? 'true' : 'false')};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

function TradeSummary({
  price,
  trade,
  allowedSlippage,
  isMobile
}: { 
  price: any; 
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType>; 
  allowedSlippage: number, 
  isMobile: boolean }) {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  const showRoute = Boolean(trade && trade.route.path.length > 2)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const fee = new BigNumber(realizedLPFee.toSignificant(2))

  return (
    <Container>
      <StyledHeaderRow onClick={() => setShowDetails(!showDetails)}>
        <Flex flexDirection="column">
          <Flex flexDirection="row" justifyContent="space-between">
              <TradePrice price={trade.executionPrice} />
            <Flex>
              <RotatingArrow ml="10px" open={showDetails} />
            </Flex>
          </Flex>
        </Flex>
      </StyledHeaderRow>

      <AnimatedDropdown open={showDetails}>
        <StyledDetailsRow p="0.5rem 0">
          <LabelRow>
            <RowFixed>
              <MouseoverTooltip
                isMobile={isMobile}
                text={t('The impact your trade has on the market price of this pool.')}
              >
                <Text fontSize="14px" color="textSubtle">
                  {t('Price Impact')}
                </Text>
              </MouseoverTooltip>
            </RowFixed>
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
          </LabelRow>

          

          <LabelRow>
            <RowFixed>
              <MouseoverTooltip
                isMobile={isMobile}
                text={
                  <Text mb="12px">{t('For each trade a %amount% fee is paid towards PLSX buy and burn', { amount: '0.29%' })}</Text>
                }
              >
                <Text fontSize="14px" color="textSubtle">
                  {t('Liquidity Provider Fee')}
                </Text>
              </MouseoverTooltip>
            </RowFixed>
            <Text fontSize="14px" textAlign="right">
              {realizedLPFee
                ? `~$${fee.multipliedBy(price).toString()} (${realizedLPFee.toSignificant(4)} ${
                    trade.inputAmount.currency.symbol
                  })`
                : '-'}
            </Text>
          </LabelRow>

          <LabelRow>
            <RowFixed>
              <MouseoverTooltip
                isMobile={isMobile}
                text={t(
                  'The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert.',
                )}
              >
                <Text fontSize="14px" color="textSubtle">
                  {isExactIn ? t('Minimum received') : t('Maximum sold')}{' '}
                  {t(`after slippage (${(allowedSlippage / 100).toFixed(2)}%)`)}
                </Text>
              </MouseoverTooltip>
            </RowFixed>
            <Text fontSize="14px" textAlign="right">
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </Text>
          </LabelRow>

          {showRoute && (
            <>
              <LabelRow>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <MouseoverTooltip
                    isMobile={isMobile}
                    text={t('Routing through these tokens resulted in the best price for your trade.')}
                  >
                    <Text fontSize="14px" color="textSubtle">
                      {t('Route')}
                    </Text>
                  </MouseoverTooltip>
                </span>
                <SwapRoute trade={trade} />
              </LabelRow>
            </>
          )}
        </StyledDetailsRow>
      </AnimatedDropdown>
    </Container>
  )
}

export interface AdvancedSwapDetailsProps {
  price?: any
  trade?: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType>
  isMobile?: boolean
}

export function AdvancedSwapDetails({ price, trade, isMobile }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  return (
    <AutoColumn gap="0px">
      {trade && (
        <TradeSummary price={price} trade={trade} allowedSlippage={allowedSlippage} isMobile={isMobile}/>
      )}
    </AutoColumn>
  )
}
