import { useTranslation } from '@pulsex/localization'
import { useState } from 'react'
import { styled } from 'styled-components'
import { Button, Text, Link, Flex, Checkbox, Message } from '@pulsex/uikit'
import Card from 'components/Card'
import { AutoColumn } from 'components/Layout/Column'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { ListLogo } from 'components/Logo'
import useTheme from 'hooks/useTheme'

interface ImportProps {
  listURL: string;
  listLogoURI: string | undefined;
  listName: string;
  listTokenLength: number;
  onAddList: () => void;
  addError: string | null;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const TextDot = styled.div`
  height: 3px;
  width: 3px;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
`

export function ImportList({ listURL, listLogoURI, listName, listTokenLength, onAddList, addError }: ImportProps) {
  const { theme } = useTheme()

  const { t } = useTranslation()

  // user must accept
  const [confirmed, setConfirmed] = useState(false)

  return (
    <Wrapper>
      <AutoColumn gap="md">
        <AutoColumn gap="md">
          <Card padding="12px 20px">
            <RowBetween>
              <RowFixed>
                {listLogoURI && <ListLogo logoURI={listLogoURI} size={40} />}
                <AutoColumn gap="sm" style={{ marginLeft: '20px' }}>
                  <RowFixed>
                    <Text bold mr="6px">
                      {listName}
                    </Text>
                    <TextDot />
                    <Text small color="textSubtle" ml="6px">
                      {listTokenLength} tokens
                    </Text>
                  </RowFixed>
                  <Link
                    small
                    external
                    ellipsis
                    maxWidth="90%"
                    href={`https://tokenlists.org/token-list?url=${listURL}`}
                  >
                    {listURL}
                  </Link>
                </AutoColumn>
              </RowFixed>
            </RowBetween>
          </Card>

          <Message variant="danger">
            <Flex flexDirection="column">
              <Text fontSize="20px" textAlign="center" color={theme.colors.failure} mb="16px">
                {t('Import at your own risk')}
              </Text>
              <Text color={theme.colors.failure} mb="8px">
                {t(
                  'By adding this list you are implicitly trusting that the data is correct. Anyone can create a list, including creating fake versions of existing lists and lists that claim to represent projects that do not have one.',
                )}
              </Text>
              <Text bold color={theme.colors.failure} mb="16px">
                {typeof 'If you purchase a token from this list, you may not be able to sell it back.'}
              </Text>
              <Flex alignItems="center">
                <Checkbox
                  name="confirmed"
                  type="checkbox"
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  scale="sm"
                />
                <Text ml="10px" style={{ userSelect: 'none' }}>
                  {t('I understand')}
                </Text>
              </Flex>
            </Flex>
          </Message>

          <Button disabled={!confirmed} onClick={onAddList}>
            {t('Import')}
          </Button>
          {addError ? (
            <Text color="failure" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {addError}
            </Text>
          ) : null}
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  )
}

