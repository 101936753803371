/* eslint-disable no-nested-ternary */
import { useMemo } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import styled from 'styled-components'
import {
  Text,
  Box,
  Heading,
  Button,
  Card,
  Flex,
  Breadcrumbs,
  LinkExternal,
  Spinner2,
  useMatchBreakpoints,
} from '@pulsex/uikit'
import Page from 'components/Layout/Page'
import { getBlockExplorerLink } from 'utils'
import truncateHash from 'utils/truncateHash'
import { CurrencyLogo } from 'pages/Info/components/CurrencyLogo'
import { formatAmount } from 'utils/formatInfoNumbers'
import Percent from 'pages/Info/components/Percent'
import SaveIcon from 'pages/Info/components/SaveIcon'
import {
  usePoolDatasQuery,
  usePoolsForTokenQuery,
  useTokenChartDataQuery,
  useTokenDataQuery,
  useTokenPriceDataQuery,
  useTokenTransactionsQuery,
} from 'state/info/hooks'
import PoolTable from 'pages/Info/components/InfoTables/PoolsTable'
import TransactionTable from 'pages/Info/components/InfoTables/TransactionsTable'
import useInfoUserSavedTokensAndPools from 'hooks/useInfoUserSavedTokensAndPoolsList'
import { ONE_HOUR_SECONDS } from 'config/constants/info'
import { useTranslation } from '@pulsex/localization'
import ChartCard from 'pages/Info/components/InfoCharts/ChartCard'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { Link, useParams } from 'react-router-dom'

dayjs.extend(duration)

const ContentLayout = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 1em;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const DEFAULT_TIME_WINDOW = dayjs.duration(1, 'weeks')

const TokenPage: React.FC<React.PropsWithChildren<{ protocol: string }>> = ({ protocol }) => {
  const { routeAddress = '' } = useParams()
  const { isXs, isSm } = useMatchBreakpoints()
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()

  // In case somebody pastes checksummed address into url (since GraphQL expects lowercase address)
  const address = routeAddress.toLowerCase()
  const tokenData = useTokenDataQuery(protocol, address)
  const poolsForToken = usePoolsForTokenQuery(protocol, address)
  const poolDatas = usePoolDatasQuery(protocol, useMemo(() => poolsForToken ?? [], [poolsForToken]))
  
  const transactions = useTokenTransactionsQuery(protocol, address)
  const chartData = useTokenChartDataQuery(protocol, address)

  // pricing data
  const priceData = useTokenPriceDataQuery(protocol, address, ONE_HOUR_SECONDS, DEFAULT_TIME_WINDOW)
  const adjustedPriceData = useMemo(() => {
    // Include latest available price
    if (priceData && tokenData && priceData.length > 0) {
      return [
        ...priceData,
        {
          time: Date.now() / 1000,
          open: priceData[priceData.length - 1].close,
          close: tokenData?.priceUSD,
          high: tokenData?.priceUSD,
          low: priceData[priceData.length - 1].close,
        },
      ]
    }
    return undefined
  }, [priceData, tokenData])

  const { v1, v2 } = useInfoUserSavedTokensAndPools(chainId)
  const { savedTokens, addToken } = protocol === 'v1' ? v1 : v2

  return (
    <Page symbol={tokenData?.symbol}>
      {tokenData ? (
        !tokenData.exists ? (
          <Card>
            <Box p="16px">
              <Text>
                {t('No pool has been created with this token yet. Create one')}
                <Link style={{ display: 'inline', marginLeft: '6px' }} to={`/add/${protocol}/${address}`}>
                  {t('here.')}
                </Link>
              </Text>
            </Box>
          </Card>
        ) : (
          <>
            {/* Stuff on top */}
            <Flex justifyContent="space-between" mb="24px" flexDirection={['column', 'column', 'row']}>
              <Breadcrumbs mb="32px">
                <Link to={protocol === 'v1' ? '/info' : '/info/v2'}>
                  <Text color="primary">{t('Info')}</Text>
                </Link>
                <Link to={protocol === 'v1' ? '/info/tokens' : '/info/v2/tokens'}>
                  <Text color="primary">{t('Tokens')}</Text>
                </Link>
                <Flex>
                  <Text mr="8px">{tokenData.symbol}</Text>
                  <Text>{`(${truncateHash(address)})`}</Text>
                </Flex>
              </Breadcrumbs>
              <Flex justifyContent={[null, null, 'flex-end']} mt={['8px', '8px', 0]}>
                <LinkExternal mr="8px" color="primary" href={getBlockExplorerLink(address, 'address', chainId)}>
                  {t('View on PulseScan')}
                </LinkExternal>
                <SaveIcon fill={savedTokens.includes(address)} onClick={() => addToken(address)} />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" flexDirection={['column', 'column', 'column', 'row']}>
              <Flex flexDirection="column" mb={['8px', null]}>
                <Flex alignItems="center">
                  <CurrencyLogo size="32px" address={address} />
                  <Text
                    ml="12px"
                    bold
                    lineHeight="0.7"
                    fontSize={isXs || isSm ? '24px' : '40px'}
                    id="info-token-name-title"
                  >
                    {tokenData.name}
                  </Text>
                  <Text ml="12px" lineHeight="1" color="textSubtle" fontSize={isXs || isSm ? '14px' : '20px'}>
                    ({tokenData.symbol})
                  </Text>
                </Flex>
                <Flex mt="8px" ml="46px" alignItems="center">
                  <Text mr="16px" bold fontSize="24px">
                    ${formatAmount(tokenData.priceUSD, { notation: 'standard' })}
                  </Text>
                  <Percent value={tokenData.priceUSDChange} fontWeight={600} />
                </Flex>
              </Flex>
              <Flex>
                <Link to={protocol === 'v1' ? `/add/V1/${address}` : `/add/V2/${address}`}>
                  <Button mr="8px" variant="secondary">
                    {t('Add Liquidity')}
                  </Button>
                </Link>
                <Link to={`/?inputCurrency=${address}`}>
                  <Button>{t('Trade')}</Button>
                </Link>
              </Flex>
            </Flex>

            {/* data on the right side of chart */}
            <ContentLayout>
              <Card>
                <Box p="24px">
                  <Text bold small color="secondary" fontSize="12px" textTransform="uppercase">
                    {t('Liquidity')}
                  </Text>
                  <Text bold fontSize="24px">
                    ${formatAmount(tokenData.liquidityUSD)}
                  </Text>
                  <Percent value={tokenData.liquidityUSDChange} />

                  <Text mt="24px" bold color="secondary" fontSize="12px" textTransform="uppercase">
                    {t('Volume 24H')}
                  </Text>
                  <Text bold fontSize="24px" textTransform="uppercase">
                    ${formatAmount(tokenData.volumeUSD)}
                  </Text>
                  <Percent value={tokenData.volumeUSDChange} />

                  <Text mt="24px" bold color="secondary" fontSize="12px" textTransform="uppercase">
                    {t('Volume 7D')}
                  </Text>
                  <Text bold fontSize="24px">
                    ${formatAmount(tokenData.volumeUSDWeek)}
                  </Text>

                  <Text mt="24px" bold color="secondary" fontSize="12px" textTransform="uppercase">
                    {t('Transactions 24H')}
                  </Text>
                  <Text bold fontSize="24px">
                    {formatAmount(tokenData.txCount, { isInteger: true })}
                  </Text>
                </Box>
              </Card>
              {/* charts card */}
              <ChartCard
                variant="token"
                chartData={chartData}
                tokenData={tokenData}
                tokenPriceData={adjustedPriceData}
              />
            </ContentLayout>

            {/* pools and transaction tables */}
            <Heading scale="lg" mb="16px" mt="40px">
              {t('Pools')}
            </Heading>

            <PoolTable protocol={protocol} poolDatas={poolDatas} />

            <Heading scale="lg" mb="16px" mt="40px">
              {t('Transactions')}
            </Heading>

            <TransactionTable transactions={transactions} />
          </>
        )
      ) : (
        <Flex mt="80px" justifyContent="center">
          <Spinner2 />
        </Flex>
      )}
    </Page>
  )
}

export default TokenPage
