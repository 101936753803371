import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { LanguageProvider } from '@pulsex/localization'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiConfig } from 'wagmi'
import { SWRConfig } from 'swr'
import { Provider } from 'react-redux'
import { dark, light, ModalProvider, ResetCSS, UIKitProvider } from '@pulsex/uikit'
import { HashRouter } from 'react-router-dom'
import { ThemeProvider as NextThemeProvider, useTheme as useNextTheme } from 'next-themes'
import { PageMeta } from 'components/Layout/Page'
import { fetchStatusMiddleware } from './hooks/useSWRContract'
import { wagmiConfig } from './utils/wagmi'
import App from './pages/App'
import store from './state'
import GlobalStyle from './style/Global'

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
  const { resolvedTheme } = useNextTheme()
  return (
    <UIKitProvider theme={resolvedTheme === 'dark' ? dark : light} {...props}>
      {children}
    </UIKitProvider>
  )
}

// Create a client
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <WagmiConfig config={wagmiConfig}>
            <NextThemeProvider forcedTheme='dark'>
              <StyledUIKitProvider>
                <LanguageProvider>
                  <SWRConfig
                    value={{
                      use: [fetchStatusMiddleware],
                    }}
                  >
                    <ModalProvider>
                      <PageMeta />
                      <ResetCSS />
                      <GlobalStyle />
                      <App />
                    </ModalProvider>
                  </SWRConfig>
                </LanguageProvider>
              </StyledUIKitProvider>
            </NextThemeProvider>
          </WagmiConfig>
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
)
