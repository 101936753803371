import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import OpenNewIcon from "../Svg/Icons/OpenNew";
import PlsIcon from "../Svg/Icons/PlsIcon";

const LinkExternal: React.FC<React.PropsWithChildren<LinkProps>> = ({ 
  children, 
  isPlsScan = false,
  ...props
}) => {
  return (
    <Link external {...props}>
      {children}
      {isPlsScan && <PlsIcon color={props.color ? props.color : "primary"} ml="4px" />}
      <OpenNewIcon color={props.color ? props.color : "primary"} ml="4px" />
    </Link>
  );
};

export default LinkExternal;
