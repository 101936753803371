import { memo } from 'react'
import styled from 'styled-components'
import { Token } from '@pulsex/sdk'
import { getBalanceNumber, getFullDisplayBalance } from '@pulsex/utils/formatBalance'
import { useTranslation } from '@pulsex/localization'
import {
  Flex,
  MetamaskIcon,
  Text,
  LinkExternal,
  TimerIcon,
  Skeleton,
  useTooltip,
  Button,
  HelpIcon,
} from '@pulsex/uikit'
import { BASE_PULSECHAIN_SCAN_URL } from 'config'
import { DeserializedPool } from '@pulsex/pools'
import { getAddress } from 'viem'
import { registerToken } from 'utils/wallet'
import Balance from 'components/Balance'
import { getPoolTimeInfo } from 'pages/Pools/helpers'

interface ExpandedFooterProps {
  pool: DeserializedPool<Token>
  account: string
}

const ExpandedWrapper = styled(Flex)`
  svg {
    height: 14px;
    width: 14px;
  }
`

const ExpandedFooter: React.FC<ExpandedFooterProps> = ({ pool, account }) => {
  const { t } = useTranslation()

  const { stakingToken, earningToken, totalStaked, stakingLimit, contractAddress, timeStamp } = pool

  const tokenAddress = earningToken.address || ''
  const poolContractAddress = getAddress(contractAddress)
  const isMetaMaskInScope = !!window.ethereum?.isMetaMask

  const { shouldShowSecondCountdown, secondsUntilStart, secondsRemaining, hasPoolStarted } = getPoolTimeInfo(
    pool,
    timeStamp,
  )

  const daysout = Math.floor(secondsRemaining / 86400)
  const hoursout = Math.floor((secondsRemaining - daysout * 86400) / 3600)
  const minutesout = Math.floor((secondsRemaining - daysout * 86400 - hoursout * 3600) / 60)

  const getTotalStakedBalance = () => {
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }

  const {
    targetRef: totalStakedTargetRef,
    tooltip: totalStakedTooltip,
    tooltipVisible: totalStakedTooltipVisible,
  } = useTooltip(t('Total amount of %symbol% staked in this pool', { symbol: stakingToken.symbol }), {
    placement: 'bottom',
  })

  return (
    <ExpandedWrapper flexDirection="column">
      <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text small>{t('Total staked')}:</Text>
        <Flex alignItems="flex-start">
          {totalStaked && totalStaked.gte(0) ? (
            <>
              <Balance small value={getTotalStakedBalance()} decimals={0} unit={` ${stakingToken.symbol}`} />
              <span ref={totalStakedTargetRef}>
                <HelpIcon color="textSubtle" width="20px" ml="6px" mt="4px" />
              </span>
            </>
          ) : (
            <Skeleton width="90px" height="21px" />
          )}
          {totalStakedTooltipVisible && totalStakedTooltip}
        </Flex>
      </Flex>
      {stakingLimit && stakingLimit.gt(0) && (
        <Flex mb="2px" justifyContent="space-between">
          <Text small>{t('Max. stake per user')}:</Text>
          <Text small>{`${getFullDisplayBalance(stakingLimit, stakingToken.decimals, 0)} ${stakingToken.symbol}`}</Text>
        </Flex>
      )}
      {shouldShowSecondCountdown && (
        <Flex mb="2px" justifyContent="space-between" alignItems="center">
          <Text small>{hasPoolStarted ? t('Ends in') : t('Starts in')}:</Text>
          {secondsRemaining || secondsUntilStart ? (
            <Flex alignItems="center">
              <Text small color="primary">
                {`${daysout}d ${hoursout}h ${minutesout}m`}
              </Text>
              <Text small ml="4px" color="primary" textTransform="lowercase">
                {t('Blocks')}
              </Text>
              <TimerIcon ml="4px" color="primary" />
            </Flex>
          ) : (
            <Skeleton width="54px" height="21px" />
          )}
        </Flex>
      )}
      <Flex mb="2px" justifyContent="flex-end">
        <LinkExternal href={`/info/token/${earningToken.address}`} bold={false} small>
          {t('See Token Info')}
        </LinkExternal>
      </Flex>
      <Flex mb="2px" justifyContent="flex-end" />
      {poolContractAddress && (
        <Flex mb="2px" justifyContent="flex-end">
          <LinkExternal
            href={`${BASE_PULSECHAIN_SCAN_URL}/address/${poolContractAddress}`}
            bold={false}
            small
          >
            {t('View Contract')}
          </LinkExternal>
        </Flex>
      )}
      {account && isMetaMaskInScope && tokenAddress && (
        <Flex justifyContent="flex-end">
          <Button
            variant="text"
            p="0"
            height="auto"
            onClick={() => registerToken(tokenAddress, earningToken.symbol, earningToken.decimals)}
          >
            <Text color="primary" fontSize="14px">
              {t('Add to Metamask')}
            </Text>
            <MetamaskIcon ml="4px" />
          </Button>
        </Flex>
      )}
    </ExpandedWrapper>
  )
}

export default memo(ExpandedFooter)
