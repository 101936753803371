import { HelpIcon, useTooltip, Box, BoxProps, Placement } from '@pulsex/uikit'
import { styled } from 'styled-components'

interface Props extends BoxProps {
  text: string | React.ReactNode
  placement?: Placement
  size?: string
  isMobile?: boolean
}

const QuestionWrapper = styled.div`
  :hover,
  :focus {
    opacity: 0.7;
  }
`

export const QuestionHelper: React.FC<Props> = ({ text, placement = 'auto', size = '16px', ...props }) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' })

  return (
    <Box {...props}>
      {tooltipVisible && tooltip}
      <QuestionWrapper ref={targetRef}>
        <HelpIcon color="textSubtle" width={size} />
      </QuestionWrapper>
    </Box>
  )
}

export const MouseoverTooltip: React.FC<Props> = ({ text, isMobile, children, ...props }) => {
  const placement = isMobile ? 'top' : 'right'
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' })

  return (
    <>
      <div ref={targetRef} {...props}>{children}</div>
      {tooltipVisible && tooltip}
    </>
  )
}
