import React from 'react'
import { ChainId } from '@pulsex/chains'
import { Navigate, Routes, Route } from 'react-router-dom'
import { chains } from 'utils/wagmi'
import { PersistGate } from 'redux-persist/integration/react'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSmartRouterManager } from 'state/user/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { persistor } from 'state'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import { NetworkModal } from 'components/NetworkModal'
import AddLiquidity from 'pages/AddLiquidity'
import RemoveLiquidity from 'pages/RemoveLiquidity'
import { VersionTag } from 'components/VersionTag'
import NotFound from 'pages/NotFound'
import Farms from 'pages/Farms'
import Pools from 'pages/Pools'
import PoolFinder from 'pages/PoolFinder'
import Token from 'pages/Info/Tokens/TokenPage'
import TokensOverview from 'pages/Info/Tokens'
import PoolsOverview from 'pages/Info/Pools'
import PoolPage from './Info/Pools/PoolPage'
import Menu from '../components/Menu'
import Swap from './Swap'
import SmartSwap from './Swap/SmartSwap'
import Liquidity from './Pool'
import Burn from './Burn'
import { InfoPageLayout } from './Info'
import Overview from './Info/Overview'

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  return null
}

export function Updaters() {
  return (
    <>
      <ListsUpdater />
      {chains.map((chain) => (
        <TransactionUpdater key={`trxUpdater#${chain.id}`} chainId={chain.id} />
      ))}
      <MulticallUpdater />
    </>
  )
}

export default function App() {
  const { chainId } = useActiveWeb3React()
  const [isSmartRouter] = useSmartRouterManager()
  const SwapPage = (chainId === ChainId.PULSECHAIN || chainId === ChainId.PULSECHAIN_TESTNET)
    && isSmartRouter ? <SmartSwap /> : <Swap />
  return (
    <>
      <GlobalHooks />
      <PersistGate loading={null} persistor={persistor}>
        <Updaters />
        <Menu>
          <Routes>
            {/* Swap / Homepage */}
            <Route path="/" element={SwapPage} />

            {/* Liquidity list */}
            <Route path="/liquidity" element={<Liquidity />} />

            {/* Add liquidity */}
            <Route path="add/:initialProtocol" element={<AddLiquidity />}>
              <Route path=":currencyIdA" />
              <Route path=":currencyIdA/:currencyIdB" />
            </Route>

            {/* Remove liquidity */}
            <Route path="remove/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />

            {/* Pools finder */}
            <Route path="/find" element={<PoolFinder />} />

            {/* Farms */}
            <Route path="/farms" element={<Farms />} />
            <Route path="/farms/history" element={<Farms />} />

            {/* Pools */}
            <Route path="/pools" element={<Pools />} />
            <Route path="/pools/history" element={<Pools />} />

            {/* Burn */}
            <Route path="/burn" element={<Burn />} />

            {/* V1 Info page */}
            <Route
              path="/info"
              element={
                <InfoPageLayout>
                  <Overview protocol='v1' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/tokens"
              element={
                <InfoPageLayout>
                  <TokensOverview protocol='v1' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/token/:routeAddress"
              element={
                <InfoPageLayout>
                  <Token protocol='v1' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/pools"
              element={
                <InfoPageLayout>
                  <PoolsOverview protocol='v1' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/pool/:routeAddress"
              element={
                <InfoPageLayout>
                  <PoolPage protocol='v1' />
                </InfoPageLayout>
              }
            />

            {/* V2 Info page */}
            <Route
              path="/info/v2"
              element={
                <InfoPageLayout>
                  <Overview protocol='v2' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/v2/tokens"
              element={
                <InfoPageLayout>
                  <TokensOverview protocol='v2' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/v2/token/:routeAddress"
              element={
                <InfoPageLayout>
                  <Token protocol='v2' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/v2/pools"
              element={
                <InfoPageLayout>
                  <PoolsOverview protocol='v2' />
                </InfoPageLayout>
              }
            />
            <Route
              path="/info/v2/pool/:routeAddress"
              element={
                <InfoPageLayout>
                  <PoolPage protocol='v2' />
                </InfoPageLayout>
              }
            />

            <Route path="*" element={<Navigate to="/not-found" replace />} />
            <Route path="/not-found" element={<NotFound />} />
          </Routes>
        <NetworkModal />
        </Menu>
        <VersionTag />
      </PersistGate>
    </>
  )
}
