import { Currency } from '@pulsex/sdk'
import { styled } from 'styled-components'
import CurrencyLogo from './CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ margin }) => margin && '4px'};
`
const HigherLogoWrapper = styled.div`
  z-index: 1;
`
const CoveredLogoWapper = styled.div<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => `-${  (sizeraw / 2).toString()  }px`} !important;
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper margin={margin}>
      {currency0 && (
        <HigherLogoWrapper>
          <CurrencyLogo currency={currency0} size={`${size.toString()}px`} style={{ marginRight: '4px' }} />
        </HigherLogoWrapper>
      )}
      {currency1 && (
        <CoveredLogoWapper sizeraw={size}>
          <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
        </CoveredLogoWapper>
      )}
    </Wrapper>
  )
}
