import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

const StyledInput = styled(NumericFormat)<{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.text)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: 24px;
  text-align: ${({ align }) => align ?? 'left'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

export const Input = memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
}: {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {

  // Using this state variable to catch global state value updates
  // and forse re-render NumericFormat component
  const [displayValue, setDisplayValue] = useState('')

  const handleInput = (val: string) => {
    onUserInput(val)
  }

  useEffect(() => {
    setDisplayValue(value.toString())
  }, [value])

  return (
    <StyledInput
      value={displayValue}
      thousandSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      placeholder={placeholder || '0.0'}
      onChange={(event) => handleInput(event.target.value)}
    />
  )
})

export default Input
