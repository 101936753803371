import { Box } from '@pulsex/uikit'
import { styled } from 'styled-components'
import { useTranslation } from '@pulsex/localization'
import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { usePlsxUsdcPrice } from 'hooks/useUSDCPrice'
import Container from './Container'

const StyledPage = styled(Box)`
  width: 100%;
  min-height: calc(100vh - 77px);
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`
const StyledContainer = styled(Container)`
  min-height: calc(100vh - 77px);
  padding-top: 16px;
  padding-bottom: 70px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

export const PageMeta: React.FC<{ symbol?: string }> = ({ symbol }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const plsxPriceUsd = usePlsxUsdcPrice()
  const pulsexPriceUsdDisplay = plsxPriceUsd ? `$${plsxPriceUsd.toFixed(7)}` : '...'

  const pageMeta = getCustomMeta(pathname, t) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  let pageTitle = pulsexPriceUsdDisplay ? [title, pulsexPriceUsdDisplay].join(' - ') : title
  if (symbol) {
    pageTitle = [symbol, title].join(' - ')
  }

  return (
    <head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <style type="text/css">{`wcm-modal{position: absolute;z-index:111;}`}</style>
    </head>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ children, symbol, ...props }) => {
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage>
        <StyledContainer {...props}>{children} </StyledContainer>
      </StyledPage>
    </>
  )
}

export default Page
